<template>
  <div class="cont" v-if="cur">
    <div class="gap"></div>
    <van-row gutter="6" class="list-text" v-if="cur">
      <van-col :span="6">已选机票</van-col>
      <van-col :span="6">{{cur.seatClass}}</van-col>
      <van-col :span="6">￥{{cur.price.seatPrice}}</van-col>
      <van-col :span="6">{{cur.corp}}</van-col>
    </van-row>
    <van-row gutter="6" class="list-text" v-if="best&&!cur.price.gpAgreement">
      <van-col :span="6">同舱公务票</van-col>
      <van-col :span="6">{{best.seatClass}}</van-col>
      <van-col :span="6">￥{{best.price.seatPrice}}</van-col>
      <van-col :span="6">{{best.corp}}</van-col>
    </van-row>
    <van-row class="list-text" v-if="!best">
      <van-col :span="6">同舱公务票</van-col>
      <van-col :span="6" style="padding-left:4px">无</van-col>
      <van-col :span="6" style="padding-left:4px">无</van-col>
      <van-col :span="6" style="padding-left:4px">无</van-col>
    </van-row>
    <div class="gap"></div>
    <span class="list-text green" v-if="cur.price.gpAgreement">
      <van-icon name="smile-comment-o" />&nbsp;您已选购公务票，请放心购买。
    </span>
    <span class="list-text green" v-else-if="!best">
      <van-icon name="smile-comment-o" />&nbsp;该仓位没有公务票。
    </span>
    <span class="list-text green" v-else-if="best.price.seatPrice>cur.price.seatPrice">
      <van-icon name="smile-comment-o" />&nbsp;您选购的机票价格低于同舱公务票价格，我们会为您保存公务票信息，请放心购买。
    </span>
    <span class="list-text warning" v-else>
      <van-icon name="smile-comment-o" />&nbsp;您选购的机票价格不低于同舱公务票价格，可能会影响报销。
    </span>
  </div>
</template>

<script>
export default {
  name: "businessUI",
  props: ["cur", "best"],
};
</script>

<style lang="less" scoped>
.cont {
  padding: 0 1rem .6rem;
}
</style>