<template>
  <!-- 保险 -->
  <div class="insure-cotain">
    <!-- 如果不需要外部框架 -->
    <van-checkbox-group v-if="hasLayout" v-model="insuranceResult" @change="updateResult">
      <van-cell
        v-for="(data,index) in list"
        icon="umbrella-circle green"
        :key="data.productId"
        :value="'￥'+data.salePrice+'/人份'"
        is-link
        class="auto-cell"
      >
        <template #title>
          <div @click="openDetail(data.insuranceDescription)">
            <span class="list-text-big">{{data.name}}</span>
          </div>
        </template>
        <template #right-icon>
          <van-checkbox :name="index" class="bigcheck" :disabled="prevent_modify.includes(corp)" />
        </template>
      </van-cell>
    </van-checkbox-group>

    <!-- 如果需要框架 -->
    <van-panel title="增值服务" v-else>
      <van-checkbox-group v-model="insuranceResult" @change="updateResult">
        <van-cell
          v-for="(data,index) in list"
          icon="umbrella-circle green"
          :key="data.productId"
          :value="'￥'+data.salePrice+'/人份'"
          is-link
          class="auto-cell"
        >
          <template #title>
            <div @click="openDetail(data.insuranceDescription)">
              <span class="list-text-big">{{data.name}}</span>
            </div>
          </template>
          <template #right-icon>
            <van-checkbox :name="index" class="bigcheck" :disabled="prevent_modify.includes(corp)" />
          </template>
        </van-cell>
        <van-notice-bar
          class="transition"
          :color="0 in insuranceResult?'#49b38a':'#ed6a0c'"
          :background="0 in insuranceResult?'#e6f9f1':'#fffbe8'"
        >
          <template #left-icon>
            <van-icon class="iconfont" class-prefix="icon" name="anquanzhongxin" />
          </template>出行有保障，安全更放心。
        </van-notice-bar>
      </van-checkbox-group>
    </van-panel>

    <!-- 规则描述弹窗 -->
    <van-action-sheet v-model="show" class="insurance-detail">
      <div style="white-space:pre-line" v-html="detail"></div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getFlightInsurance } from "@/api/aircraft";
import { DefaultSelect, PreventModify } from "@/common/config.js";
export default {
  name: "Insurance",
  props: ["corp", "hasLayout"],
  data() {
    return {
      default_select: DefaultSelect, // 需要默认选中的供应商
      prevent_modify: PreventModify, // 不可修改保险的供应商
      list: [], // 保险列表
      detail: "", // 保险详情
      show: false, //详情开关
      insuranceResult: [], //选择结果
    };
  },
  mounted() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.insuranceResult = [];
      this.fetchInsuranceInfo(() => {
        // 默认选中
        if (this.default_select.includes(this.corp)) {
          for (let i = 0; i < this.list.length; i++) {
            this.insuranceResult.push(i);
          }
        }
      });
    },
    // 单击事件
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 拉取保险
    fetchInsuranceInfo(cb = () => {}) {
      getFlightInsurance({
        corp: this.corp,
      }).then((response) => {
        this.list = [response];
        cb();
      });
    },
    // 打开详情
    openDetail(detail) {
      this.show = true;
      detail = detail.replace(/\\n/g, "");
      detail = detail.replace(/\\r/g, "");
      this.detail = detail.replace(/\\t/g, "");
    },
    // 同步修改父组件的“已选保险列表”
    updateResult() {
      let result = [];
      this.insuranceResult.forEach((index) => {
        result.push(this.list[index]);
      });
      this.$emit("update:updateInsuranceResult", result);
    },
  },
  watch: {
    // 路由动画
    corp() {
      this.init();
    },
  },
};
</script>

<style src="@/styles/insurance.css" scoped></style>
<style lang="less" scoped>
@import '../styles/theme.less';
.insure-cotain{
  .fanxian {
    font-size: @font-size-md;
    color: @gray-5;
  }
  .tuigai {
    width: 100%;
  }
  .custom-title {
    padding-right: 4px;
  }
  .auto-cell {
    align-items: center;
    padding-right: 3rem;
  }
}
</style>