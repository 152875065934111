<template>
  <van-panel title="公务报销">
    <BusinessUI
      v-if="toList[toIndex]&&toList[toIndex].price.gpAgreement==true"
      :cur="toList[toIndex]"
      :best="toCheapestGP"
    ></BusinessUI>
    <div v-else-if="backList" class="buss">
      <div class="gip"></div>
      <span class="list-text-big">去程</span>
      <BusinessUI :cur="toList[toIndex]" :best="toCheapestGP" />
      <span class="list-text-big">返程</span>
      <BusinessUI :cur="backList[backIndex]" :best="backCheapestGP" />
    </div>
    <BusinessUI v-else :cur="toList[toIndex]" :best="toCheapestGP" />
  </van-panel>
</template>

<script>
import BusinessUI from "./BusinessUI";
export default {
  name: "businessTicket",
  props: ["toList", "toIndex", "backList", "backIndex"],
  components: { BusinessUI },
  computed: {
    // 去程最便宜的公务票
    toCheapestGP: function () {
      let ticket = null;
      this.toList.forEach((element) => {
        let min = ticket || this.toList[this.toIndex];
        // 同仓位，公务票，价格相同或耕地
        if (
          min.seatCode == element.seatCode &&
          element.price.gpAgreement &&
          min.price.seatPrice <= element.price.seatPrice
        )
          ticket = element;
      });
      return ticket;
    },
    // 返程最便宜
    backCheapestGP: function () {
      let ticket = null;
      this.backList.forEach((element) => {
        let min = ticket || this.backList[this.backIndex];
        // 同仓位，公务票，价格相同或耕地
        if (
          min.seatCode == element.seatCode &&
          element.price.gpAgreement &&
          min.price.seatPrice <= element.price.seatPrice
        )
          ticket = element;
      });
      return ticket;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  activated() {},
  methods: {
    // 单击事件
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
  },
};
</script>
<style lang="less" scoped>
.buss {
  .list-text-big {
    padding-left: 1rem;
  }
}
</style>